import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';

import { HomePageCarouselItem } from '@interfaces/ICarousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { CarouselItem } from './CarouselItem';

export function HomePageCarousel({
  carouselItems,
}: {
  carouselItems: Array<HomePageCarouselItem>;
}) {
  // static variable, seemed to have no effect being in a useEffect dependant on carouselItems
  const totalSlides = carouselItems ? carouselItems.length : 0;
  return (
    <div className="home-page-carousel">
      <CarouselProvider
        naturalSlideWidth={100} // for ratio
        naturalSlideHeight={100} // for ratio
        totalSlides={totalSlides}
        visibleSlides={1}
        infinite={true}
        isPlaying={false}
        isIntrinsicHeight={true}
        interval={3000}
      >
        <Slider classNameAnimation={'custom-slider-animation'}>
          {carouselItems &&
            carouselItems
              .sort((a, b) => a.position - b.position) // sort slide based on position
              .map((child, idx) => (
                <Slide index={idx} key={idx}>
                  <CarouselItem
                    carouselItem={child}
                    totalSlides={totalSlides}
                    priority={idx === 0} // only prioritise first slide
                  />
                </Slide>
              ))}
        </Slider>
        {totalSlides > 1 && <DotGroup />}
      </CarouselProvider>
    </div>
  );
}
